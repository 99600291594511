body {
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
}

.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.title {
    margin: 30px 0;
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
}

.btn-container {
    display:block;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.btn {
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    background-color: #3f51b5;
    color: #fff;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s ease;
    min-height: 200px;
    min-width: 200px;
}

.btn:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.btn h3 {
    margin-top: 10px;
    font-size: 1.5rem;
    font-weight: bold;
}